<template>
    <sub-page-header title="Časté otázky" />

    <section class="questions-and-answers">
        <div class="container">
            <faq-accordion @toggle-accordion="toggleAccordion" v-for="(accordion, index) in accordions" :key="index" :accordion="accordion" :index="index" />
        </div>
    </section>

    <!-- <whats-next /> -->
</template>

<script>
    import SubPageHeader from '../components/SubPageHeader';
    import FaqAccordion from "../components/FaqAccordion";
    // import WhatsNext from "../components/WhatsNext";

    export default {
        components: { SubPageHeader, FaqAccordion,
            // WhatsNext
        },
        data() {
            return {
                accordions: [
                    {
                        question: 'Jak dlouho trvá vytvořit nový web?',
                        answer: 'Doba potřebná k vytvoření nového webu se může lišit v závislosti na rozsahu a složitosti projektu, požadavcích klienta, dostupnosti materiálů a obsahu, technologie použité pro vytvoření webu, počet revizí a další. Při spolupráci s námi se snažíme přizpůsobit potřebám každého klienta individuálně a vytvořit web, který plně odpovídá jeho požadavkům a očekáváním.<br><br>Doba vytvoření webu se obvykle pohybuje od několika týdnů až po několik měsíců, v závislosti na rozsahu a složitosti projektu. Předběžně by se dala doba odhadnout podle balíčků:<br><br><b>Express Web</b> - Vývoj se odehrává v týdnech, průměrný web by se dal odhadnout na 3 týdny. (2 až 8 týdnů)<br><b>Unikátní Web</b> - Tvorba na míru je vždy delší, bavíme se zde o měsících, nejčastěji je spolupráce na 2 měsíce. (2 měsíce až několikaroční spolupráce)',
                        open: false
                    },
                    {
                        question: 'Jaká je cena za web?',
                        answer: 'Cena za tvorbu webu je vždy individuální v závislosti na požadavcích zákazníka. Cena se dá odhadnout v závislosti na použitých technologiích. Pokud k tvorbě použijeme redakční systém, cena se pohybuje od 20 000kč, průměrný web poté stojí 30 000kč. Pokud by ale web byl tvořen na míru pomocí kódu, se specifickými funkcemi, cena webu začíná na 60 000kč a průměrná cena systému na míru se pohybuje okolo 100 000kč.',
                        open: false
                    },
                    // {
                    //     question: 'Jaké jsou vaše schopnosti s optimalizací vyhledávání? (SEO)',
                    //     answer: 'Pomocí správně nastaveného SEO dokážeme dlouhodobě zvýšit nebo udržet vaši pozici ve vyhledavačích. Nastavení zahrnuje analýzu klíčových slov, technickou optimalizaci, on-page optimalizaci, off-page optimalizaci a analýzu výsledků.',
                    //     open: false
                    // },
                    {
                        question: 'Jaký typ webových platforem a technologií používáte?',
                        answer: 'Pro tvorbu s pomocí redakčního systému používáme celosvětově používaný WordPress. Pokud kódujeme web, používáme především frameworky Laravel a Vue (HTML, SCSS, PHP, JS).',
                        open: false
                    },
                    // {
                    //     question: 'Jak často mohu aktualizovat obsah na svém webu?',
                    //     answer: 'Pokud je vaše stránka vytvořená pomocí redakčního systému, máte vždy možnost se přihlásit do administrace a obsah aktualizovat a editovat sami. V případě kódového řešení si můžete nechat vytvořit administrační systém na míru nebo vám obsah můžeme aktualizovat my.',
                    //     open: false
                    // },
                    {
                        question: 'Jaký je postup pro testování nových webových stránek?',
                        answer: 'V rámci vývoje testujeme responzivitu zařízeních různých rozměrů, webové funkce, rychlost načítání a provádíme uživatelské testování.',
                        open: false
                    },
                    {
                        question: 'Jsou weby také přizpůsobeny pro používání na telefonech a tabletech?',
                        answer: 'Ano, všechny námi vytvořené webové stránky jsou přizpůsobeny pro všechny zařízení.',
                        open: false
                    },
                ]
            }
        },
        methods: {
            toggleAccordion(index){
                this.accordions.forEach((accordion, loopIndex) => {
                    if(index != loopIndex){
                        accordion.open = false;
                    }
                });

                this.accordions[index].open = !this.accordions[index].open;
            }
        }
    }
</script>

<style lang="scss" scoped>

.questions-and-answers{
    padding: 80px 0;

    position: relative;
    z-index: 2;

    background: #fff;

    .container{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 20px;
    }
}

@media only screen and (max-width: 650px){
    .questions-and-answers{
        padding: 50px 0;
    }
}

</style>