<template>
    <div class="accordion-wrapper" @click="$emit('toggleAccordion', index)" :class="accordion.open ? 'open' : ''">
        <div class="accordion-header">
            <h3>{{ accordion.question }}</h3>
            <font-awesome-icon icon="chevron-down" :style="{ fontSize: '25px' }" color="#373737" class="font-awesome-icon" />
        </div>

        <div class="accordion-content" v-html="accordion.answer"></div>
    </div>
</template>

<script>
    export default {
        props: ["accordion", "index"],
    }
</script>

<style lang="scss" scoped>

.accordion-wrapper{
    background: #F6F6F6;
    border-radius: 20px;
    padding: 20px 25px;

    display: flex;
    flex-direction: column;
    
    overflow: hidden;

    position: relative;

    .accordion-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        h3{
            color: #373737;
            font-size: 21px;
            font-weight: 700;
            user-select: none;
        }

        .font-awesome-icon{
            transition: .3s ease-in-out;
        }
    }

    .accordion-content{
        color: #616161;
        font-size: 18px;
        font-weight: 500;

        opacity: 0;
        max-height: 0;
        margin-top: 0;
        overflow-y: hidden;
        transition: all 1s ease-in-out;
    }
}

.open{
    .font-awesome-icon{
        transform: rotate(180deg);
    }

    .accordion-content{
        opacity: 1;
        max-height: 500px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 700px){
    .accordion-wrapper{
        .accordion-header{
            h3{
                font-size: 18px;
            }
        }

        .accordion-content{
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 550px){
    .accordion-wrapper{
        .accordion-content{
            font-size: 14px;
        }
    }
}

</style>