<template>
    <router-link to="/" class="logo">
        <img src="../assets/main-logo.png" alt="fit pixels logo">
        <span>Fit&nbsp;Pixels.</span>
    </router-link>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.logo{
    display: flex;
    align-items: center;
    gap: 10px;

    color: #000;
    text-decoration: none;

    img{
        width: 50px;
    }

    span{
        font-weight: bold;
        font-size: 33px;
    }
}

@media only screen and (max-width: 800px){
    .logo{
        gap: 10px !important;

        img{
            width: 40px !important;
        }

        span{
            font-size: 30px !important;
        }
    }
}

</style>