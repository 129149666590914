import { createRouter, createWebHashHistory } from 'vue-router';
import Home from "./views/Home";
import QuestionsAndAnswers from "./views/QuestionsAndAnswers";
import Services from "./views/Services";
import Contact from "./views/Contact";
import WhatsNextPage from "./views/WhatsNextPage";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home
        },
        {
            path: "/q&a",
            name: "QuestionsAndAnswers",
            component: QuestionsAndAnswers
        },
        {
            path: "/services",
            name: "Services",
            component: Services
        },
        {
            path: "/contact",
            name: "Contact",
            component: Contact
        },
        {
            path: "/whats-next",
            name: "Whats Next",
            component: WhatsNextPage
        }
    ],
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        console.log(to.hash);

        if (to.hash && to.hash.length > 1) {
            let hash = "";

            if(to.hash.charAt(0) === "#"){
                hash = to.hash.substring(1);
            }else{
                hash = to.hash;
            }

            const el = document.getElementById(hash);

            el.scrollIntoView();
            // el.scrollIntoView({ behavior: "smooth" });
        } else {
            window.scrollTo(0, 0);
        }
    }
});

export default router;