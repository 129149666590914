<template>
    <section class="top-section">
        <div class="container">
            <page-logo />

            <div class="navigations">
                <div class="page-navigation">
                    <h3>Stránky</h3>

                    <ul>
                        <li><router-link to="/" @click="scrollToTop">Domů</router-link></li>
                        <li><router-link to="/q&a" @click="scrollToTop">Časté dotazy</router-link></li>
                        <li><router-link to="services" @click="scrollToTop">Služby</router-link></li>
                        <li><router-link to="/contact" @click="scrollToTop">Kontakt</router-link></li>
                    </ul>
                </div>

                <div class="fast-contact-navigation">
                    <h3>Rychlý kontakt</h3>

                    <ul>
                        <li><a href="mailto:info@fitpixels.cz" target="_blank">info@fitpixels.cz</a></li>
                        <li><a href="tel:704338431">+420 704 338 431</a></li>
                        <li class="owner-contact"><a href="mailto:business@martinzach.cz" target="_blank">Správce webu</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section class="bottom-section">
        <div class="container">
            <span class="label">&copy;&nbsp;2023&nbsp;FitPixels.</span>

            <!-- <div class="socials">
                <a href="https://www.facebook.com/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'facebook-f']" color="#979797" :style="{ fontSize: '25px'}" />
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                    <font-awesome-icon :icon="['fab', 'instagram']" color="#979797" :style="{ fontSize: '25px'}" />
                </a>
            </div> -->
        </div>
    </section>
</template>

<script>
    import PageLogo from "./PageLogo.vue";

    export default {
        components: { PageLogo },
        methods: {
            scrollToTop(){
                window.scrollTo(0, 0);
            }
        }
    }
</script>

<style lang="scss" scoped>

.top-section{
    padding: 80px 0;
    border-top: 2px solid rgba(151, 151, 151, 0.4);

    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
    }
    
    // .logo{
    //     display: flex;
    //     align-items: center;
    //     gap: 10px;

    //     color: #000;
    //     text-decoration: none;

    //     img{
    //         width: 50px;
    //     }

    //     span{
    //         font-weight: bold;
    //         font-size: 33px;
    //     }
    // }

    .navigations{
        display: flex;
        gap: 170px;

        margin-top: 10px;

        h3{
            font-weight: 700;
            font-size: 26px;
            margin-bottom: 20px;
        }

        ul{
            list-style: none;
            margin: 0;

            display: flex;
            flex-direction: column;
            gap: 10px;
            
            li{
                font-weight: 600;

                a{
                    color: #ACACAC;
                    text-decoration: none;
                }
            }

            .owner-contact{
                text-decoration: underline;
            }
        }
    }
}

.bottom-section{
    padding: 30px 0;
    border-top: 2px solid rgba(151, 151, 151, 0.4);

    .container{
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .label{
        font-size: 16px;
        color: #979797;
        font-weight: 600;
    }

    .socials{
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 800px){
    .top-section{
        .container{
            gap: 10px !important;
        }
        
        // .logo{
        //     gap: 10px !important;

        //     img{
        //         width: 40px !important;
        //     }

        //     span{
        //         font-size: 30px !important;
        //     }
        // }

        .navigations{
            gap: 70px;

            h3{
                font-size: 23px;
            }
        }
    }
}

@media only screen and (max-width: 650px){ 
    .top-section{
        padding: 50px 0 !important;

        .container{
            gap: 30px !important;
            flex-direction: column !important;
            align-items: center !important;
        }

        .navigations{
            margin-top: 0 !important;
            flex-direction: column;
            gap: 30px !important;
            text-align: center;

            h3{
                font-weight: 700;
                font-size: 20px !important;
                margin-bottom: 10px !important;
            }

            ul{
                list-style: none;
                margin: 0;
                gap: 7px !important;
                
                li{
                    font-weight: 600;

                    a{
                        color: #ACACAC;
                        text-decoration: none;
                    }
                }

                .owner-contact{
                    text-decoration: underline;
                }
            }
        }
    }

    .bottom-section{
        padding: 20px 10px !important;

        .label{
            font-size: 14px !important;
        }

        .socials{
            display: flex;
            align-items: center;
            gap: 15px !important;
        }
    }
}

</style>