<template>
    <div>
        <!-- <whats-next /> -->
    </div>
</template>

<script>
    // import WhatsNext from "@/components/WhatsNext.vue";

    export default {
        // components: { WhatsNext }
    }
</script>

<style lang="scss" scoped>

</style>