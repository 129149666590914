<template>
  <Header :position="$route.path != '/contact' && $route.path != '/whats-next' ? 'absolute' : ''" />

  <router-view />

  <Footer />
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: 'App',
  components: { Header, Footer }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 18px;
}

html{
  font-family: 'Inter', sans-serif;
}

body{
  overflow-x: hidden;
}

.container{
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

.text-half-image{
	padding: 80px 0;

	.container{
		display: flex;
		align-items: center;
		gap: 50px;

		.col{
      flex-basis: calc(50% - 25px);
      flex-grow: 1;
      flex-shrink: 0;
		}

		.col-image img{
			width: 100%;
		}

		.col-content{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
		}
	}
}

.btn{
  background: #616161;
  padding: 15px 30px;
  color: #fff !important;
  font-weight: 600;
  font-size: 20px;
  border-radius: 20px;

  display: flex;
  transition: .3s;
  text-decoration: none;
  justify-content: center;

  border: none;
}

.btn-primary{
  background: #8540E8;
  color: #fff !important;

  &:hover{
    background: darken(#8540E8, 10%);
  }
}

.btn-highlight{
  background: #5500E9;
  color: #fff !important;

  &:hover{
    background: darken(#5500E9, 10%);
  }
}

.btn-secondary{
  background: #fff;
  border: 4px solid #767676;
  font-weight: 700;
  color: #616161 !important;
  padding: 11px 26px;

  &:hover{
    border: 4px solid darken(#5500E9, 10%);
    background: darken(#5500E9, 10%);
    color: #fff !important;
  }
}

.btn-hightlight-outline{
  background: #fff;
  border: 4px solid #5500E9;
  font-weight: 800;
  color: #000000 !important;
  padding: 15px 30px;
  font-size: 23px;

  &:hover{
    border: 4px solid darken(#712BEC, 10%);
    background: darken(#712BEC, 10%);
    color: #fff !important;
  }
}

.title{
  font-size: 52px;
  font-weight: 700;
}

.sub-title{
  font-size: 23px;
  font-weight: 700;
  color: #9D66EC;
}

p, ul{
  color: #646464;
}

ul{
  margin-left: 20px;
}

.highlight-text{
  color: #6C63FF;
  font-size: inherit;
}

@media only screen and (max-width: 900px){
  .text-half-image{
    .container{
      flex-direction: column;
      gap: 30px !important;

      .col-content{
        align-items: center;
        gap: 20px;
        text-align: center;

        ul{
          list-style-position: inside;
        }
      }

      .col-image{
        order: 2;
      }
    }
  }
}

@media only screen and (max-width: 800px){
  *{
    font-size: 16px;
  }

  .btn{
    font-size: 18px;
  }
}

</style>
