<template>
    <section class="landing-section">
        <div class="content">
            <h1 class="title">{{ title }}</h1>
        </div>

        <div class="background-layer">
            <div class="circle circle1"></div>
            <div class="circle circle2"></div>
            <div class="circle circle3"></div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ["title"]
    }
</script>

<style lang="scss" scoped>

.landing-section{
    width: 100%;
    height: 40vh;
    position: relative;

    .content{
        position: relative;
        z-index: 1;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-top: 90px;
        // padding-bottom: 
    }

    .background-layer{
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(110px);

        .circle{
            position: absolute;
            border-radius: 50%;
        }

        .circle1{
            background: #A749F0;
            width: 15%;
            padding-bottom: 15%;
            top: 30%;
            left: 10%;
        }
        
        .circle2{
            background: #FFC806;
            width: 10%;
            padding-bottom: 10%;
            top: 40%;
            left: 0%;
        }
        
        .circle3{
            background: #387CFF;
            width: 14%;
            padding-bottom: 14%;
            bottom: -50%;
            right: 0;
        }
    }
}

@media only screen and (max-width: 1580px){
    .landing-section{
        .background-layer{
            .circle1{
                width: 17%;
                padding-bottom: 17%;
                top: 30%;
                left: 10%;
            }
            
            .circle2{
                width: 13%;
                padding-bottom: 13%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 16%;
                padding-bottom: 16%;
                bottom: -30%;
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 1250px){
    .landing-section{
        .background-layer{
            .circle1{
                width: 20%;
                padding-bottom: 20%;
                top: 30%;
                left: 10%;
            }
            
            .circle2{
                width: 13%;
                padding-bottom: 13%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 16%;
                padding-bottom: 16%;
                bottom: -10%;
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 1250px){
    .landing-section{
        .background-layer{
            .circle1{
                width: 20%;
                padding-bottom: 20%;
                top: 30%;
                left: 10%;
            }
            
            .circle2{
                width: 13%;
                padding-bottom: 13%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 16%;
                padding-bottom: 16%;
                bottom: -10%;
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 800px){
    .landing-section{
        .background-layer{
            .circle1{
                width: 27%;
                padding-bottom: 27%;
                top: 30%;
                left: 10%;
            }
            
            .circle2{
                width: 15%;
                padding-bottom: 15%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 20%;
                padding-bottom: 20%;
                bottom: -10%;
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 700px){
    .landing-section{
        .content{
            .title{
                font-size: 30px !important;
            }
        }

        .background-layer{
            .circle1{
                width: 35%;
                padding-bottom: 35%;
                top: 30%;
                left: 10%;
            }
            
            .circle2{
                width: 20%;
                padding-bottom: 20%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 25%;
                padding-bottom: 25%;
                bottom: 0;
                right: 0;
            }
        }
    }
}

@media only screen and (max-width: 550px){
    .landing-section{
        
        .background-layer{
            filter: blur(70px);
        }

        .background-layer{
            .circle1{
                width: 45%;
                padding-bottom: 45%;
                top: 30%;
                left: 0;
            }
            
            .circle2{
                width: 25%;
                padding-bottom: 25%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 30%;
                padding-bottom: 30%;
                bottom: 0;
                right: 0;
            }
        }
    }
}

</style>