<template>
    <sub-page-header title="Služby" />

    <section class="text-half-image where-we-specify">
        <div class="container">
            <div class="col col-content">
                <h2 class="title">Zaměřujeme se specificky na tvorbu pro posilovny, fitness trenéry a výživové poradce.</h2>
                <p>Máme zálibu v oboru zdravého životního stylu a sdílíme, jak firemně, tak osobně hodnoty sportu. Zároveň rádi pracujeme se sportovním designem, který je vždy moderní, nadčasový a plynule navazující.</p>
        
                <ul>
                    <li>Svižnost, Rychlost, Pružnost</li>
                    <li>Přizpůsobení a efektivita</li>
                    <li>Týmovost a disciplína</li>
                    <li>Vytrvalost a úspěch</li>
                </ul>
        
                <router-link class="btn btn-primary" to="/contact">Kontaktovat</router-link>
            </div>

            <div class="col-image">
                <img src="../assets/illustrations/mobile-run.svg" alt="žena bežící vedle velikého telefonu ilustrační obrázek">
            </div>
        </div>
    </section>

    <section class="functions-we-provide" id="functions-we-provide">
        <div class="container">
            <div class="sub-title">V čem jsme jedničky?</div>
            <div class="title">Obvyklé funkce webů posiloven a fitness trenérů <font-awesome-icon icon="thumbs-up" size="1x" color="#5500E9" /></div>

            <div class="functions-wrapper">
                <div class="function" v-for="(item, index) in functionsWeOffer" :key="index" :class="{ 'express-web': item.type == 'expressweb', 'unikatni-web': item.type == 'unikatniweb' }">
                    <div class="function-header">
                        <h4 class="function-title">{{ item.title }}</h4>
                        <span class="tag">#{{ item.type }}</span>
                    </div>

                    <span class="content-text">{{ item.value }}</span>
                </div>
            </div>

            <!-- <router-link to="#whats-next" class="btn btn-hightlight-outline">Jaká je nabídka?</router-link> -->
        </div>
    </section>

    <section class="text-half-image design">
        <div class="container">
            <div class="col col-content">
                <h3 class="title">Design <font-awesome-icon icon="pencil" :style="{ fontSize: '40px' }" color="#5500E9" class="icon" /></h3>
                <p>Nabízíme <span class="highlight-text">web-design</span>, který tvoříme vždy na základě potřeb daného zákazníka. Vzhled a tématika se odvíjí od požadavků klienta a <span class="highlight-text">jeho značky</span>. Zaměřujeme se na fitness odvětví, a líbí se nám dynamická, moderní a svěží grafika.</p>

                <ul>
                    <li><span class="highlight-text">Rychlá</span> a flexibilní aplikace</li>
                    <li><span class="highlight-text">Moderní</span> a tématický styl</li>
                    <li>Přehledný a účel plnící</li>
                </ul>
            </div>
            <div class="col col-image">
                <img src="../assets/illustrations/design-mobile.svg" alt="žena designující na telefonu ilustrační obrázek">
            </div>
        </div>
    </section>

    <section class="text-half-image development">
        <div class="container">
            <div class="col col-image">
                <img src="../assets/illustrations/development-mobile.svg" alt="žena kódující na notebooku vedle velikého telefonu ilustrační obrázek">
            </div>
            <div class="col col-content">
                <h3 class="title">Vývoj <font-awesome-icon icon="code"  :style="{ fontSize: '40px' }" color="#5500E9" class="icon" /></h3>
                <p>Weby tvoříme nejčastěji pomocí <span class="highlight-text">WordPress</span> redakčního systému, díky kterému zvládnete na Vašem webu provádět jednoduché úpravy bez nutnosti znalostí programování.</p>

                <p>Pokud ale přesně víte, co chcete, a potřebujete např. automatický systém pro předplatné, i to zvládneme na jedničku.</p>

                <ul>
                    <li>Přehledná a moderní aplikace</li>
                    <li>Zaměření na cíl webu</li>
                    <li><span class="highlight-text">Pokročilé prezentace</span> na míru</li>
                    <li>Rozsáhlé systémy pro správu a <span class="highlight-text">management</span></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="text-half-image marketing">
        <div class="container">
            <div class="col col-content">
                <h3 class="title">Marketing <font-awesome-icon icon="bullhorn" :style="{ fontSize: '40px' }" color="#5500E9" class="icon" /></h3>
                <p>Staráme se o vaši <span class="highlight-text">značku</span> a pomocí optimalizace pro vyhledavače vás můžeme dostat na co nejvyšší postavení.</p>
                                
                <ul>
                    <li><span class="highlight-text">Grafická tvorba</span> a editace</li>
                    <li><span class="highlight-text">SEO optimalizace</span></li>
                    <li>Správa sociálních sítí a tvorba obsahu</li>
                </ul>
            </div>
            <div class="col col-image">
                <img src="../assets/illustrations/marketing-mobile.svg" alt="žena designující na velikém telefonu ilustrační obrázek">
            </div>
        </div>
    </section>

    <!-- <whats-next /> -->
</template>

<script>
    // import WhatsNext from '@/components/WhatsNext';
    import SubPageHeader from "@/components/SubPageHeader.vue";

    export default {
        components: {
            // WhatsNext,
            SubPageHeader
        },
       data() {
        return {
            functionsWeOffer: [
                {
                    title: "Rozvrhy lekcí",
                    type: "expressweb",
                    value: "Seznam dostupných fitness lekcí nebo tréninkových sezení, spolu s datem, časem a místem konání."
                },
                {
                    title: "Profily trenérů",
                    type: "expressweb",
                    value: " Informace o trenérech posiloven, včetně jejich kvalifikace, zkušeností a oblastí odborného zaměření."
                },
                {
                    title: "Online platby",
                    type: "unikatniweb",
                    value: "Způsob, jak klienti mohou jednoduše a rychle platit online, bez nutnosti platby hotově nebo na místě."
                },
                {
                    title: "Uživatelské rozhraní",
                    type: "unikatniweb",
                    value: "Bezpečné login rozhraní pro uživatele, které umožňuje přihlášení k jejich účtu a přístup ke svým tréninkovým plánům, statistikám a platebním informacím."
                },
                {
                    title: "Blog nebo články",
                    type: "expressweb",
                    value: "Sekce na webu, která poskytuje vzdělávací články nebo příspěvky o fitness, zdraví a výživě."
                },
                {
                    title: "Fotky před a po",
                    type: "expressweb",
                    value: "Obrázky nebo reference, které ukazují pokrok nebo výsledky bývalých klientů nebo členů posilovny nebo trenéra."
                },
                {
                    title: "Online rezervace",
                    type: "unikatniweb",
                    value: "Způsob, jak si klienti mohou online naplánovat a zaplatit lekce nebo tréninková sezení, což zjednodušuje a zefektivňuje celý proces."
                },
                {
                    title: "Administrační rozhraní",
                    type: "unikatniweb",
                    value: "Funkce, která umožňuje majitelům fitness center, osobním trenérům a poradcům v oblasti výživy spravovat své webové stránky a aplikace, včetně správy uživatelských účtů, tréninkových plánů a plateb."
                },
                {
                    title: "Často kladené dotazy",
                    type: "expressweb",
                    value: "Stránka na webu, která odpovídá na běžné dotazy týkající se služeb, zařízení nebo programů posiloven."
                },
                {
                    title: "Reference",
                    type: "expressweb",
                    value: "Recenze a zpětná vazba od bývalých klientů nebo členů, což může pomoci potenciálním klientům učinit informované rozhodnutí o posilovně nebo trenérovi."
                },
                {
                    title: "Integrace sociálních sítí",
                    type: "unikatniweb",
                    value: "Odkazy na sociální média posiloven nebo trenérů, které mohou být použity k sdílení fotografií, novinek a aktualizací s klienty a sledujícími."
                },
                {
                    title: "Automatická fakturace",
                    type: "unikatniweb",
                    value: "Funkce, která umožňuje automatické vytváření a odesílání faktur a inkasování platby od klientů za členství, lekce nebo tréninková sezení."
                },
            ]
        }
       }, 
    }
</script>

<style lang="scss" scoped>

.where-we-specify{
    background: #fff;
    position: relative;
    z-index: 2;

    .title{
        font-size: 34px;
    }

    p, ul{
        font-weight: 600;
    }

    img{
        width: 100%;
    }
}

.functions-we-provide{
    background: #F6F6F6;
    padding: 80px 0;

    .container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;

        .title{
            text-align: center;
        }

        .functions-wrapper{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            align-items: start;
            grid-auto-flow: dense;
            
            width: 100%;
            
            .function{
                width: 100%;

                padding: 25px;
                border-radius: 0 20px 20px 20px;

                display: flex;
                flex-direction: column;
                gap: 10px;

                .function-header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    .function-title{
                        font-size: 22px;
                        font-weight: 800;
                    }

                    .tag{
                        padding: 5px 10px;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 700;
                        border-radius: 10px;
                    }
                }

                .content-text{
                    font-weight: 600;
                    font-size: 17px;
                }

                &:nth-child(-n+4) {
                    grid-column: 1;
                }

                &:nth-child(n+5):nth-child(-n+8) {
                    grid-column: 2;
                }

                &:nth-child(n+9) {
                    grid-column: 3;
                }

                &:nth-child(4n+1) {
                    grid-row: 1;
                }

                &:nth-child(4n+2) {
                    grid-row: 2;
                }

                &:nth-child(4n+3) {
                    grid-row: 3;
                }

                &:nth-child(4n+4) {
                    grid-row: 4;
                }
            }

            .express-web{
                background: #fff;
                border: 5px solid #5500E9;

                .tag{
                    background: #5500E9; 
                }

                .content-text{
                    font-weight: 700;
                    font-size: 17px;
                    color: #ACACAC;
                }
            }

            .unikatni-web{
                color: #fff;
                background: #712BEC;

                .tag{
                    border: 3px solid #fff;
                }
            }
        }
    }

    .btn{
        font-size: 18px;
        padding: 15px;
        // width: 100%;
    }
}

.design{
    background: #fff;

    p, ul, li{
        font-weight: 600;
        font-size: 16px;
    }
}
.development{
    background: #F6F6F6;

    .col-content{
        align-items: flex-end;
    }

    ul{

        list-style-position: inside;
    }

    p, ul, li{
        font-weight: 600;
        font-size: 16px;
        text-align: right;
    }
}

.marketing{
    background: #fff;

    p, ul, li{
        font-weight: 600;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1470px){
    .functions-we-provide{
        .title{
            font-size: 33px !important;
        }
        
        .container{
            .functions-wrapper{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                
                .function{
                    font-size: 15px;
                    width: calc(50% - 10px);

                    .function-header{
                        .function-title{
                            font-size: 20px;
                        }
                    }
                }

                .express-web{
                    order: 1;
                }

                .unikatni-web{
                    order: 2;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px){
    .functions-we-provide{
        .title{
            font-size: 30px !important;
        }
        
        .container{
            .functions-wrapper{
                .function{
                    width: 100%;

                    padding: 15px;

                    .function-header{
                        .function-title{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .development .container .col-content{
        align-items: center !important;

        p, ul, li{
            text-align: center;
        }
    }
}

@media only screen and (max-width: 800px){
    .where-we-specify{
        .title{
            font-size: 30px;
        }
    }

    .functions-we-provide{
        .content-text{
            text-align: center !important;
        }
        
        .btn{
            width: 100%;
        }
    }

    .design, .development, .marketing{
        .title{
            font-size: 30px !important;

            .icon{
                font-size: 30px !important;
            }
        }
    }
}

</style>