<template>
    <section class="landing-section">
        <div class="content">
            <div class="container">
                <h2 class="header-text">Tvorba webových stránek</h2>
                <!-- <span class="sub-text">Tvoříme webové stránky pro posilovny, trenéry a výživové poradce.</span> -->
    
                <!-- <video class="gif-animation" src="../assets/notebook-open-close.webm" muted autoplay loop>
                    <source  type="video/webm">
                    Váš prohlížeč nepodporuje přehrávání videí.
                </video> -->

                <img class="gif-animation" src="../assets/notebook-open-close.jpg" alt="animation notebook open close">
            </div>
        </div>

        <div class="background-layer">
            <div class="circle circle1"></div>
            <div class="circle circle2"></div>
            <div class="circle circle3"></div>
        </div>

        <div class="scroll-down-icon" @click="scrollToWhyToChooseUs">
            <a>
                <font-awesome-icon icon="chevron-down" size="2x" color="#000" />
            </a>
        </div>
    </section>

    <section class="text-half-image why-to-choose-us" id="why-to-choose-us">
        <div class="container">
            <div class="col col-content">
                <span class="sub-title">Proč si vybrat nás?</span>
                <h3 class="title">Zaměřujeme se na tvorbu webů pro posilovny, fitness trenéry a výživové poradce.</h3>
                <p>Máme zálibu v oboru zdravého životního stylu a sdílíme, jak firemně, tak osobně základní hodnoty sportu.</p>
                <ul>
                    <li>Svižnost, Rychlost, Pružnost</li>
                    <li>Přizpůsobení a efektivita</li>
                    <li>Týmovost a disciplína</li>
                    <li>Vytrvalost a úspěch</li>
                </ul>
                <router-link class="btn btn-primary" to="/services#functions-we-provide">Zjistit více</router-link>
            </div>
            <div class="col col-image">
                <img src="../assets/illustrations/mobile-run.svg" alt="běžící žena vedle velikého telefonu ilustrační obrázek">
            </div>
        </div>
    </section>

    <section class="what-we-offer">
        <div class="container">
            <div class="sub-title">Naše služby</div>
            <div class="title">Co nabízíme</div>

            <div class="options-wrapper">
                <div class="option">
                    <div class="option-header">
                        <h4>Keativní design</h4>
                        <div class="circle">
                            <font-awesome-icon icon="pen-nib" color="#fff" :style="{ fontSize: '25px' }" class="we-offer-icon" />
                        </div>
                    </div>
                    
                    <span class="option-content">Tvoříme grafické návrhy, které vám pomohou s jedinečnou a kvalitní prezentací pro veřejnost. Již u návrhů přemýšlíme nad funkcemi a použitelností webové aplikace.</span>
                </div>
                
                <div class="divider"></div>

                <div class="option">
                    <div class="option-header">
                        <h4>Vývoj webu</h4>
                        <div class="circle">
                            <font-awesome-icon icon="code" color="#fff" :style="{ fontSize: '25px' }" class="we-offer-icon" />

                        </div>
                    </div>
                    
                    <span class="option-content">Tvorba webu a jeho správa je naší hlavní činností, kterou se zabýváme. Webová vizitka je důležitou složkou každého podnikání.</span>
                </div>
                 
                <div class="divider"></div>
                
                <div class="option">
                    <div class="option-header">
                        <h4>Marketing</h4>
                        <div class="circle">
                            <font-awesome-icon icon="chart-simple" color="#fff" :style="{ fontSize: '25px' }" class="we-offer-icon" />
                        </div>
                    </div>
                    
                    <span class="option-content">Chceme, aby Váš web našli jak ti, kteří u vás nakupují pravidelně, tak i noví zákazníci. Proto se snažíme zlepšit jeho pozici ve vyhledávačích.</span>
                </div>
            </div>
        </div>
    </section>

    <section class="text-half-image about-us">
        <div class="container">
            <div class="col col-image">
                <img src="../assets/illustrations/playing-cards.svg" alt="hrací karty s charakterem ilustrační obrzázek">
            </div>

            <div class="col col-content">
                <div class="sub-title">O nás</div>
                <div class="title">Jsme agentura, která se zabývá marketingem a online prezentací pro fitness obory.</div>
                <p class="text-content">Tvoříme weby a vše okolo nich s radostí! Chceme pomoci českým podnikatelům zvýšit důvěru jejich zákazníků a vylepšit celkovou kvalitu vystupování na internetu.</p>
                <router-link to="/services" class="btn btn-primary">Zjistit více</router-link>
            </div>
        </div>
    </section>

    <!-- <whats-next /> -->
</template>

<script>
    // import WhatsNext from "../components/WhatsNext";

    export default {
        // components: { WhatsNext },
        methods: {
            scrollToWhyToChooseUs(){  
                const whyToChooseUs = document.getElementById("why-to-choose-us");
                whyToChooseUs.scrollIntoView({ behavior: 'smooth' });
            }
        },
        mounted(){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            
            window.addEventListener("resize", () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        }
    }
</script>

<style lang="scss" scoped>

.landing-section{
    position: relative;
    width: 100%;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;


    padding-top: 100px;
    padding-bottom: 100px;

    .content{
        position: relative;
        z-index: 1;
        min-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            text-align: center;
        }

        .header-text{
            font-size: 77px;
            font-weight: 600;
        }

        .sub-text{
            color: #7B7B7B;
            font-size: 22px;
            font-weight: 500;
        }

        .gif-animation{
            width: 372px;
            height: 372px;
            border-radius: 20px;
            background: #000;
            position: relative;
        }
    }

    .background-layer{
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: blur(110px);

        .circle{
            position: absolute;
            border-radius: 50%;
        }

        .circle1{
            background: #A749F0;
            width: 20%;
            padding-bottom: 20%;
            top: 20%;
            left: 10%;
        }
        
        .circle2{
            background: #FFC806;
            width: 13%;
            padding-bottom: 13%;
            top: 40%;
            left: 0%;
        }
        
        .circle3{
            background: #387CFF;
            width: 17%;
            padding-bottom: 17%;
            bottom: 20%;
            right: 0%;
        }
    }

    .scroll-down-icon{
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);
        z-index: 1;

        animation-name: scroll-down-icon;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

    @keyframes scroll-down-icon{
        0%{  
            bottom: 15px;
        }
        16%{
            bottom: 25px;
        }
        33%{
            bottom: 5px;
        }
        50%{
            bottom: 15px;
        }
    }
}

.why-to-choose-us{
    position: relative;
    z-index: 2;

    .title{
        font-size: 34px;
    }
}

.what-we-offer{
    background: #F6F6F6;
    padding: 80px 0;

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .title{
        margin-bottom: 15px;
    }

    .options-wrapper{
        background: #fff;
        border-radius: 20px;
        padding: 35px;
        display: flex;
        gap: 25px;
        margin: 0 100px;

        .divider{
            width: 5px;
            background: linear-gradient(to bottom, rgba(232, 232, 232, 0.4), rgba(232, 232, 232, 1), rgba(232, 232, 232, 0.4));
            flex-shrink: 0;
            border-radius: 5px;
        }

        .option{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 30px;

            .option-header{
                width: 100%;

                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                h4{
                    font-size: 29px;
                    font-weight: 700;
                }

                .circle{
                    width: 45px;
                    height: 45px;
                    background: #8946E9;
                    border-radius: 50%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .option-content{
                color: #ACACAC;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
}

.about-us{
    .title{
        font-size: 34px;
    }
}

@media only screen and (max-width: 1300px){
    .landing-section{
        .content{
            .container{
                gap: 15px;
            }

            .header-text{
                font-size: 50px !important;
            }

            .sub-text{
                font-size: 18px;
            }

            .gif-animation{
                width: 230px;
                height: 230px;
                border-radius: 20px;
                background: #000;
                position: relative;
            }
        }
    }

    .what-we-offer{
        .options-wrapper{
            margin: 0;
        }
    }
}

@media only screen and (max-width: 1100px){
    .landing-section{
        .content{
            gap: 20px !important;

            .header-text{
                font-size: 60px !important;
            }

            .sub-text{
                font-size: 20px;
            }

            .gif-animation{
                width: 300px;
                height: 300px;
            }
        }

        .background-layer{
            .circle1{
                width: 30%;
                padding-bottom: 30%;
            }
            
            .circle2{
                width: 17%;
                padding-bottom: 17%;
            }
            
            .circle3{
                background: #387CFF;
                width: 20%;
                padding-bottom: 20%;
            }
        }
    }

    .why-to-choose-us{
        padding: 50px 0;

        .title{
            font-size: 28px !important;
        }

        .sub-title{
            font-size: 20px;
        }
    }

    
    .what-we-offer{
        .options-wrapper{
            border-radius: 15px;
            padding: 20px;
            gap: 20px;

            .option{
                gap: 15px;
                margin-bottom: 20px;

                .option-header{

                    h4{
                        font-size: 24px;
                    }

                    .circle{
                        width: 35px;
                        height: 35px;

                        .we-offer-icon{
                            font-size: 18px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 850px){
    .what-we-offer{
        .title{
            margin-bottom: 20px;
        }

        .options-wrapper{
            flex-direction: column;

            .divider{
                width: 100%;
                height: 5px;
                background: linear-gradient(to right, rgba(232, 232, 232, 0.4), rgba(232, 232, 232, 1), rgba(232, 232, 232, 0.4));
            }

            .option{
                flex-direction: column;
                gap: 20px;
                margin-bottom: 0;

                .option-content{
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px){
    .landing-section{
        // min-height: 100%;
        min-height: calc(var(--vh, 1vh) * 100);

        .content{
            .container{
                gap: 10px !important;
            }

            .header-text{
                font-size: 38px !important;
            }

            .sub-text{
                font-size: 16px;
            }

            .gif-animation{
                width: 200px;
                height: 200px;
                border-radius: 15px;
                margin-top: 10px;
            }
        }

        .background-layer{
            .circle1{
                width: 60%;
                padding-bottom: 60%;
                top: 10%;
                left: -20%;
            }
            
            .circle2{
                width: 35%;
                padding-bottom: 35%;
                top: 40%;
                left: 0;
            }
            
            .circle3{
                width: 35%;
                padding-bottom: 35%;
                bottom: 10%;
                right: 0;
            }
        }
        
        .scroll-down-icon{
            bottom: 20px;
        }
    }

    .what-we-offer{
        padding: 50px 0;

        .sub-title{
            font-size: 20px;
        }

        .title{
            font-size: 30px;
            margin-bottom: 10px;
        }

        .options-wrapper{
            background: #fff;
            border-radius: 15px;

            h4{
                font-size: 20px;
            }

            .circle{
                width: 30px;
                height: 30px;
                background: #8946E9;
                border-radius: 50%;

                display: flex;
                justify-content: center;
                align-items: center;
            }

            .option-content{
                font-size: 14px;
            }
        }
    }

    .about-us{
        .title{
            font-size: 30px;
        }
    }
}

// @media only screen and (max-width: 340px){
//     .landing-section{
//         .content{
//             .gif-animation{
//                 width: 150px;
//                 height: 150px;
//             }
//         }
//     }
// }

</style>