<template>
    <div class=" text-half-image contact-page">
        <flash-message :type="flashMessageType" :message="flashMessage" />

        <div class="container">
            <div class="col contact-form">
                <div class="form-header">
                    <div class="title">Kontaktujte nás</div>
                    <span>Chcete si domluvit meeting, nebo se jen zeptat?</span>
                </div>

                <Form @submit="sendForm" v-slot="{ errors }" class="form-tag" :class="{ 'form-sended-once': formSendedOnce }">
                    <div class="half-split">
                        <div class="input-group">
                            <label>Jméno</label>
                            <Field name="firstName" :rules="[isRequired, minTwoLength, maxTenLength]" max="10" v-model="firstName" />
                            <span v-show="errors.firstName" class="error-message">{{ errors.firstName }}</span>
                        </div>
    
                        <div class="input-group">
                            <label>Příjmení</label>
                            <Field name="lastName" :rules="[isRequired, minTwoLength, maxTenLength]" v-model="lastName" />
                            <span v-show="errors.lastName" class="error-message">{{ errors.lastName }}</span>
                        </div>
                    </div>

                    <div class="input-group">
                        <label>Email</label>
                        <Field name="email" :rules="[isRequired, minTwoLength, isValidEmail]" v-model="email" />
                        <span v-show="errors.email" class="error-message">{{ errors.email }}</span>
                    </div>

                    <div class="input-group">
                        <label>Volba <router-link to="/whats-next" target="_blank">(Zjistit nabídku)</router-link></label>

                        <div class="input-button-switch">
                            <input type="button" value="Mám zájem o služby" :class="{ active: option == 'interested' }" @click="changeOption('interested')" />
                            <input type="button" value="Mám dotaz" :class="{ active: option == 'question' }" @click="changeOption('question')" />
                            <input type="button" value="Jiné" :class="{ active: option == 'other' }" @click="changeOption('other')" />
                        </div>
                    </div>

                    <div class="input-group">
                        <label>Zpráva</label>
                        <Field as="textarea" rows="10" name="message" v-model="message" :rules="[isRequired, minTenLength]"></Field>
                        <span v-show="errors.message" class="error-message">{{ errors.message }}</span>
                    </div>

                    <button class="btn btn-highlight submit-button" :class="{ 'disabled': Object.keys(errors).length > 0 || !firstName || !lastName || !email || !message || formSendedOnce }">Odeslat</button>
                </Form>
            </div>

            <div class="col contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163930.50326005483!2d14.325542135680347!3d50.05980577947033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b939c0970798b%3A0x400af0f66164090!2sPraha!5e0!3m2!1scs!2scz!4v1677967674614!5m2!1scs!2scz" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>

    <section class="straight-way">
        <div class="container">
            <div class="sub-title">Kontakty</div>
    
            <div class="cols-wrapper">
                <div class="col">
                    <div class="title">Přímá cesta</div>
                </div>
    
                <div class="col">
                    <div class="menu-title">Agentura</div>
                    <span><a href="mailto:info@fitpixels.cz" target="_blank">info@fitpixels.cz</a></span>
                    <span><a href="tel:704338431">+420 704 338 431</a></span>
                </div>
    
                <div class="col">
                    <h5 class="menu-title">Správce webu</h5>
                    <span>Martin Zach</span>
                    <span><a href="mailto:business@martinzach.cz" target="_blank">business@martinzach.cz</a></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { Form, Field } from "vee-validate";
    import emailjs from 'emailjs-com';
    import FlashMessage from "../components/FlashMessage";

    export default {
        components: {
            Form, 
            Field,
            FlashMessage
        },
        data() {
            return {
                firstName: "",
                lastName: "",
                email: "",
                option: "interested",
                message: "",

                // eslint-disable-next-line no-undef
                emailjs_api_key: process.env.VUE_APP_EMAILJS_API_KEY,

                flashMessage: "",
                flashMessageType: "",

                formSendedOnce: false
            }
        },
        methods: {
            changeOption(option){
                this.option = option;
            },
            isRequired(value){
                return value ? true : "Toto pole je povinné.";
            },
            minTwoLength(value){
                return value.length > 2 ? true : "Položka musí být minimálně 3 znaky."
            },
            minTenLength(value){
                return value.length >= 10 ? true : "Položka musí být minimálně 10 znaků dlouhá."
            },
            maxTenLength(value){
                return value.length <= 10 ? true : "Položka musí být maximálně 10 znaků."
            },
            isValidEmail(value) {
                const emailPattern = /^[\w-.]+@[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/;
                return emailPattern.test(value) ? true : "Email musí být ve správném formátu.";
            },
            async sendForm(){
                this.formSendedOnce = true;
                
                emailjs.send('service_ec1lg0b', 'template_q3cdobm', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    option: this.option,
                    message: this.message
                }, this.emailjs_api_key)
                .then(response => {
                    console.log('SUCCESS!', response.status, response.text);

                    this.flashMessageType = "success";
                    this.flashMessage = "Formulář byl úspěšně odeslán.";

                }, error => {
                    console.log('FAILED...', error);
                    
                    this.flashMessageType = "danger";
                    this.flashMessage = "Formulář se nepodařilo odeslat. Kontaktujte nás na info@fitpixels.cz";

                    this.formSendedOnce = false;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

.contact-page{
    .container{
        align-items: stretch;
    }

    .contact-form{
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-header{
            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                color: #ACACAC;
                font-weight: 600;
            }
        }

        .form-tag{
            display: flex;
            flex-direction: column;
            gap: 20px;

            position: relative;

            .half-split{
                width: 100%;

                display: flex;
                gap: 25px;
            }

            .input-group{
                display: flex;
                flex-direction: column;
                gap: 3px;
                width: 100%;

                label{
                    color: #727272;
                    font-weight: 600;
                }

                input, textarea{
                    padding: 15px 20px;
                    border-radius: 20px;
                    border: 2px solid rgba(128, 128, 128, 0.5);
                    box-shadow: 0 1px 2px rgba(64, 64, 64, 0.25);

                    background: #fff;
                    font-weight: 600;
                    color: #9B9B9B;
                    font-size: 15px;

                    font-family: inherit !important;

                    &:focus{
                        outline: none;
                    }
                }

                textarea{
                    resize: vertical;
                }

                a{
                    font-size: 15px;
                    color: inherit;
                }

                .input-button-switch{
                    display: flex;

                    input{
                        width: 100%;

                        border-radius: 0;
                        text-align: left;

                        &:first-child{
                            border-radius: 20px 0 0 20px;
                        }

                        &:last-child{
                            border-radius: 0 20px 20px 0;
                        }
                    }

                    .active{
                        border: 2px solid #712BEC;
                        color: #712BEC;
                        background: rgb(113, 43, 236, 0.1);
                    }
                }
            }

            .submit-button{
                transition: .3s;
                user-select: none;
            }

            .submit-button.disabled{
                background: lighten(#712BEC, 20%);
                pointer-events: none;
            }

            .error-message{
                color: #fa3852 !important;
                font-weight: 500;
            }
        }

        .form-sended-once{
            &::before{
                // content: "Děkujeme za vaši zprávu, ozveme se vám hned, jak to bude možné.";
                content: "";
                position: absolute;
                top: -10px;
                left: -10px;
                bottom: -10px;
                right: -10px;
                background: rgba(223, 223, 223, 0.473);
                border-radius: 20px;

                transition: 0.3s;

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                font-size: 30px;
                font-weight: bold;

                padding: 20px;
            }
        }
    }

    .contact-map{
        iframe{
            width: 100%;
            height: 100%;

            filter: grayscale(100%);

            border-radius: 20px;
        }
    }
}

.straight-way{
    background: #F6F6F6;
    padding: 80px 0;

    .cols-wrapper{
        display: flex;
        justify-content: space-between;

        .col{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 20px;
        }

        .menu-title{
            font-size: 29px;
            font-weight: 700;
        }

        span{
            font-size: 18px;
            font-weight: 700;
            color: #797979;

            a{
                color: inherit;
            }
        }
    }
}

@media only screen and (max-width: 1120px){
    .contact-page{
        .contact-form{
            padding: 30px 0;

            .form-header{
                flex-direction: column;

                .title{
                    font-size: 40px;
                }
            }

            .form-tag{
                .half-split{
                    gap: 20px;
                    flex-direction: column;
                }

                .input-button-switch{
                    display: flex;

                    flex-direction: column;

                    input{
                        border-radius: 0;

                        &:first-child{
                            border-radius: 20px 20px 0 0 !important;
                        }

                        &:last-child{
                            border-radius: 0 0 20px 20px !important;
                        }
                    }
                }
            }
        }

        .contact-map{
            iframe{
                width: 100%;
                min-height: 400px;
            }
        }
    }
}

@media only screen and (max-width: 1000px){
    .straight-way{
        .title{
            font-size: 35px;
        }

        .sub-title{
            font-size: 25px;
        }

        padding: 80px 0;

        .cols-wrapper{
            .menu-title{
                font-size: 24px;
                font-weight: 700;
            }

            span{
                font-size: 16px;
                font-weight: 700;
                color: #797979;

                a{
                    font-size: inherit;
                    color: inherit;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px){
    .contact-page{
        .contact-form{
            padding: 0;

            form{
                .half-split{
                    gap: 20px;
                    flex-direction: row;
                }

                .input-button-switch{
                    flex-direction: row;

                    input{
                        border-radius: 0;

                        &:first-child{
                            border-radius: 20px 0 0 20px !important;
                        }

                        &:last-child{
                            border-radius: 0 20px 20px 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px){
    
    .straight-way{
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title{
            font-size: 30px;
        }

        .sub-title{
            font-size: 20px;
        }

        padding: 50px 0;

        .cols-wrapper{
            flex-direction: column;
            align-items: center;
            gap: 30px;

            .col{
                align-items: center;
                gap: 15px;

                .menu-title{
                    font-size: 18px;
                }
    
                span{
                    font-size: 14px;
    
                    a{
                        font-size: inherit;
                        color: inherit;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .contact-page{
        .contact-form{
            .form-header{
                .title{
                    font-size: 30px;
                }
            }

            form{
                .half-split{
                    gap: 20px;
                    flex-direction: column !important;
                }

                .input-button-switch{
                    flex-direction: column !important;

                    input{
                        border-radius: 0;

                        &:first-child{
                            border-radius: 20px 20px 0 0 !important;
                        }

                        &:last-child{
                            border-radius: 0 0 20px 20px !important;
                        }
                    }
                }
            }
        }
    }
}

</style>