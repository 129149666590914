<template>
    <div class="main-header" :class="{ 'absolute-position': position == 'absolute'}">
        <div class="container">
            <page-logo />
    
            <nav class="navigation">
                <ul>
                    <li v-for="(item, i) in navigation" :key="i">
                        <router-link :to="item.path" class="nav-item" :class="{
                            active: item.path == $route.path,
                            'btn btn-primary': item.type == 'btn' }">
                            <span>{{ item.text }}</span>
                            <div class="dot" v-if="item.type != 'btn'"></div>
                        </router-link>
                    </li>
                </ul>
            </nav>

            <font-awesome-icon icon="bars" class="navigation-btn-mobile" @click="openMenu" />
        </div>
    </div>

    <transition name="slide-top" appear mode="out-in">
        <div class="mobile-menu" v-show="showMobileMenu">
            <div class="main-section">
                <div class="top-in-main">
                    <page-logo />
                    <font-awesome-icon icon="xmark" class="close-icon" @click="closeMenu" />
                </div>
    
                <div class="buttons-section">
                    <div class="button" v-for="(item, index) in navigation" :key="index" @click="redirectToPage(item.path)" :class="{ active: $route.path == item.path }">
                        <span class="icon-wrapper">
                            <font-awesome-icon :icon="item.icon" class="icon" />
                        </span>
                        <span class="text">{{ item.text }}</span>
                    </div>
    
                    <!-- <div class="divider"></div>
    
                    <a href="https://www.instagram.com" target="_blank" class="button">
                        <span class="icon-wrapper">
                            <font-awesome-icon :icon="['fab', 'instagram']" class="icon" />
                        </span>
                        <span class="text">Instagram</span>
                    </a>
    
                    <a href="https://www.facebook.com" target="_blank" class="button">
                        <span class="icon-wrapper">
                            <font-awesome-icon :icon="['fab', 'facebook-f']" class="icon" />
                        </span>
                        <span class="text">Facebook</span>
                    </a> -->
                </div>
            </div>
    
            <div class="bottom-section">
                @&nbsp;{{ new Date().getFullYear() }}&nbsp;<span class="highlight-text">FitPixels</span>.
            </div>
        </div>
    </transition>
</template>

<script>
    import PageLogo from "../components/PageLogo";

    export default {
        components: { PageLogo },
        props: ["position"],
        data() {
            return {
                showMobileMenu: false,
                navigation: [
                    {
                        path: "/",
                        text: "Domů",
                        icon: "house",
                    },
                    {
                        path: "/q&a",
                        text: "Časté otázky",
                        icon: "question"
                    },
                    {
                        path: "/services",
                        text: "Služby",
                        icon: "briefcase"
                    },
                    {
                        path: "/contact",
                        text: "Kontakt",
                        icon: "phone",
                        type: "btn"
                    },
                ]
            }
        },
        methods: {
            openMenu(){
                this.showMobileMenu = true;
            },
            closeMenu(){
                this.showMobileMenu = false;
            },
            redirectToPage(path){
                this.$router.push(path);
                this.showMobileMenu = false;
            }
        }
    }
</script>

<style lang="scss" scoped>

.slide-top-enter-active, .slide-top-leave-active {
  transition: transform 0.5s;
}

.slide-top-enter-from, .slide-top-leave-to {
  transform: translateY(-100%);
}

.slide-top-enter-to, .slide-top-leave-from {
  transform: translateY(0);
}

.absolute-position{
    position: absolute;
}

.main-header{
    width: 100%;
    padding: 30px 0;
    z-index: 100;

    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navigation{
        ul{
            list-style: none;

            display: flex;
            align-items: center;
            gap: 30px;

            li{
                .nav-item{
                    color: #B5B5B5;
                    text-decoration: none;
                    font-size: 20px;
                    font-weight: 600;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 6px;

                    &:not(.btn){  
                        padding-top: 10px;
                    }

                    .dot{
                        border-radius: 50%;
                        width: 4px;
                        height: 4px;
                        transition: .3s;
                    }

                    transition: .3s;
                    &:hover{
                        color: #8540E8;

                        .dot{
                            background: #8540E8;
                            position: relative;
                            
                            transition: ease-in-out;
                            animation-name: dot-swing;
                            animation-duration: 2s;
                            animation-iteration-count: infinite;
                        }

                        @keyframes dot-swing{
                            0%{
                                left: 0;
                            }
                            16%{
                                left: 10px;
                            }
                            33%{
                                left: -10px;
                            }
                            50%{
                                left: 0;
                            }
                            100%{

                            }
                        }
                    }
                }

                .active{
                    color: #8540E8;

                    .dot{
                        background: #8540E8;
                    }
                }
            }
        }
    }

    .navigation-btn-mobile{
        font-size: 30px;
        display: none;
    }
}

.mobile-menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    overflow: hidden;

    background: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-section{
        padding: 30px 20px;

        display: flex;
        flex-direction: column;
        gap: 30px;

        .top-in-main{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .close-icon{
                font-size: 35px;
            }
        }

        .buttons-section{
            display: flex;
            flex-direction: column;
            gap: 5px;

            .button{
                border-radius: 20px;
                padding: 15px 15px;

                display: flex;
                gap: 10px;
                text-decoration: none;

                .icon-wrapper{
                    width: 25px !important;

                    display: flex;
                    justify-content: center;

                    .icon{
                        font-size: 20px;
                        color: #7B7B7B;
                    }
                }

                .text{
                    font-size: 17px;
                    font-weight: 700;
                    color: #7B7B7B;
                }
            }

            .active{
                background: #5500E9 !important;

                .icon{
                    color: #fff !important;
                }

                .text{
                    color: #fff;
                }
            }

            .divider{
                width: 100%;
                height: 2px;
                background: #D3D3D3;
                border-radius: 5px;
            }
        }
    }

    .bottom-section{
        padding: 15px 20px;
        border-top: 2px solid rgba(151, 151, 151,  0.4);

        display: flex;
        justify-content: center;

        color: #979797;
        font-size: 14px;
        font-weight: 500;

        .highlight-text{
            font-size: 14px !important;
            font-weight: 500;
            color: #5500E9;
        }
    }
}

@media only screen and (max-width: 800px){
    .logo{
        img{
            width: 40px !important;
        }

        span{
            font-size: 30px !important;
        }
    }

    .navigation{
        ul{
            gap: 20px !important;
        }
    }
}

@media only screen and (max-width: 720px){
    .navigation{
        display: none;
    }

    .navigation-btn-mobile{
        display: block !important;
    }
}

</style>