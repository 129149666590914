import { createApp } from 'vue'
import App from './App.vue'
import router from "./router.js";
import VueScrollTo from 'vue-scrollto'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronDown, faPenNib, faCode, faChartSimple, faCheck, faCrown, faThumbsUp, faPencil, faBullhorn, faBars, faXmark, faHouse, faBriefcase, faUsers, faQuestion, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

library.add({faChevronDown, faPenNib, faCode, faChartSimple, faCheck, faCrown, faFacebookF, faInstagram, faThumbsUp, faPencil, faBullhorn, faBars, faXmark, faHouse, faBriefcase, faUsers, faQuestion, faPhone});

const app = createApp(App);

app.use(router);
app.use(VueScrollTo);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app');
